@import "./src/assets/scss/main.scss";

























































































































































.cart-item {
    display: flex;
    align-items: center;
    border-top: 1px solid #dddddd;
    // border-bottom: 1px solid #DDDDDD;
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;

    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        padding-top: 14px;
    }

    &__img {
        margin-right: 25px;

        @include media-breakpoint-down(md) {
            order: 1;
            margin-right: 12px;
        }

        img {
            width: 140px;
height: 112px;
border-radius: 12px;
            // max-width: 100%;
            // height: auto;
            @include media-breakpoint-down(md) {
                max-width: 71px;
                height: auto;
            }
        }
    }
    &__noimg{
     height: 112px;
    max-width: 140px;
    width: 100%;
    margin-right: 25px;
      background: url(../assets/nophoto.png) 50% 50% no-repeat #EBEBEB;

      border-radius: 12px;
           @include media-breakpoint-down(md) {
                max-width: 71px;
                width: 100px;
               height: 112px;
                 margin-right: 12px;
            }
              @include media-breakpoint-down(sm) {
                 background: url(../assets/nophoto.png) 50% 50% no-repeat #EBEBEB;
                 background-size: contain;
height: 72px;
              }
    }

    &__name {
        margin-right: 15px;
        display: flex;
        flex-direction: column;
        margin-top: -5px;
        width: 23%;
        font-size: 18px;
        line-height: 1.2;
        @include media-breakpoint-down(md) {
            order: 2;
            width: 60%;
        }

        span {
            font-family: Montserrat;

            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #828282;
        }

        @include media-breakpoint-down(md) {
            font-size: 16px;
        }
    }

    &__counter {
        padding-right: 112px;

        @include media-breakpoint-down(md) {
            order: 4;
            margin-left: auto;
            width: 50%;
            padding-right: 0 !important;
        }
    }

    &__price {
        font-weight: 600;
        font-size: 16px;
        font-weight: 600;
        line-height: 142.69%;
        padding-right: 60px;

        @include media-breakpoint-down(md) {
            order: 3;
            white-space: nowrap;
        }
    }

    &__remove {
        cursor: pointer;

        @include media-breakpoint-down(md) {
            order: 3;
            position: absolute;
            top: 28px;
            right: 11px;
        }
    }
}

.counter {
    font-family: Montserrat;
    font-size: 16px;
    display: flex;
    align-items: center;
    background: #f5f5f5;
    border-radius: 12px;
    line-height: 142.69%;
    height: 40px;

    @include media-breakpoint-down(md) {
        width: 106px;
        margin-left: auto;
    }

    &__decrement {
        width: 40px;
        text-align: center;
      //  cursor: pointer;
    }

    &__value {
        font-weight: 600;
        text-align: center;
        min-width: 25px;
    }

    &__increment {
        width: 40px;
        text-align: center;
        //cursor: pointer;
    }
}

.cart-item__descmob {
    order: 3;
    width: 50%;
    font-size: 12px;
    line-height: 20px;
    font-family: Montserrat;
    color: #828282;
    margin-top: 8px;
      @include media-breakpoint-down(md){
        font-weight: 400 !important;
      }
}

.cart-item {
    position: relative;
}
.kkal{
    color:#000;
}
.disc{
    display: inline-block;
    width: 4px;
    height: 4px;
    background: #828282;
    border-radius: 50%;
    position: relative;
    top: -2px;
}
.cart-item__price-mob{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400 !important;
font-size: 16px !important;
color: #000000 !important;
}
.counter__decrement{
    border: none;
}
.counter__increment{
    border: none;
}
